<template>
  <div id="PROJECT_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Project"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t('project.field.projects') }}</label>
        <button class="btn-action" @click="projectSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="projects">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="projectBadgeRemove(index)"
              :text="`${item.name}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <ProjectSelectorModal v-if="projectSelectorShow" :show.sync="projectSelectorShow" @cancel="projectSelectorCancel" @ok="projectSelectorOk"/>
    
  </div>
</template>

<script>
import { objectClone } from '@/helpers'

export default {
  name: 'ProjectBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    ProjectSelectorModal: () => import('@/components/modal/ProjectSelectorModal'),
    
  },
  props: {
    show: { type: Boolean, required: true }
    , projectList: { type: Array, default: () => [] }
  },
  data() {
    return {
      modalShow: false
      , projectSelectorShow: false
      , projects: []
    }
  },
  created() {
    this.modifiedList = []
  },
  beforeMount() {
    this.projects.splice(0, this.projects.length, ...this.projectList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.projects.splice(0, this.projects.length, ...this.projectList)
        }
      }
    }
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , projectSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.projectSelectorShow = false
    }
    , projectBadgeRemove: function(index) {
      this.projects.splice(index,1)
    }
    , projectSelectorToggle() {
      this.projectSelectorShow = true
    }
    , projectSelectorOk({ details }) {
      this.projects.push( ...details.map(i => { return { uuId: i.uuId, name: i.name }}) )
    }
    , ok() {
      this.$emit('ok', objectClone(this.projects))
      this.$emit('update:show', false)
    }
  }
}
</script>